.Main {
    background-color: #F7F8FA;

    &__popup {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        width: 473px;
        height: 85px;
        background: #A9CF54;
        border-radius: 0 0 0 40px;

        &-icon {
            margin-right: 20px;
        }

        &-msg {
            color: #202020;
            font-size: 22px;
        }
    }

    &__title {
        position: absolute;
        margin: 67px 0 0 45px;
        max-width: 870px;
        font-size: 80px;
        color: #202020;
        z-index: 1;
    }

    &__back {
        position: relative;
        top: 72px;
        left: 30px;
    }

    &__form {
        display: flex;
        position: relative;
        left: 717px;
        bottom: 151px;
        z-index: 2;
        &-inp {
            margin-right: 20px;
        }

        &-btn {
            cursor: pointer;
            outline: none;
            border: none;
            background: transparent;
            margin-left: 20px;
        }
    }

    .container.mb80 {
        margin-bottom: 80px;
    }
}
