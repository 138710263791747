.register {
  margin: 0 auto;
  max-width: 500px;

  background-color: #ddd;
  border-radius: 40px;

  padding: 70px 50px 60px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;

  width: 100%;
  margin: 0 auto;
  padding: 10px 0;

  background-color: #fff;

  border: 1px solid #d8d8d7;
  border-radius: 5px;

  cursor: pointer;

  font-size: 16px;

  @media (max-width: 429.98px) {
    font-size: 12px;
  }
}

.or {
  color: #5c624f;

  text-align: center;
  padding: 20px 0;
}
