@font-face {
  font-family: 'Fontin Sans CR';
  src: url('../../../assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.woff2');
}

.form {
  display: flex;
  flex-direction: column;

  border-radius: 30px;
  background-color: #e2faad;

  padding: 33px 35px;
  margin: 0 23px;

  &_inputs {
    & input {
      width: 100%;

      font-size: 16px;
      font-family: 'Fontin Sans CR';

      border-radius: 40px;
      border: 1px solid #8e908c;

      padding: 15.5px 20px;
      margin-bottom: 20px;

      &:focus {
        outline: none;
      }

      &,
      &::placeholder {
        color: #8e8d8d;
      }
    }
  }

  & button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 27px 0;

    font-size: 22px;
  }

  &_action {
    display: flex;
    justify-content: space-around;

    margin-top: 10.5px;
    & a {
      text-decoration: underline;
      color: #202020;
      font-size: 14px;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;

  position: relative;

  & input {
    width: 14px;
    height: 14px;

    opacity: 0;

    position: absolute;
    left: -2px;
    z-index: 2;

    cursor: pointer;
  }

  & input:checked + &_span::before {
    opacity: 1;
  }

  &_span {
    display: block;
    position: relative;

    margin-right: 8px;

    border: 1px solid #8e908c;
    border-radius: 3px;
    background-color: #f7f8fa;

    width: 15px;
    height: 15px;

    overflow: hidden;

    &::before {
      content: '';
      display: block;

      width: 4px;
      height: 8px;

      position: absolute;
      top: calc(50% - 6.5px);
      left: calc(50% - 3px);

      transform: rotate(45deg);

      border-bottom: 2px solid #96b515;
      border-right: 2px solid #96b515;

      opacity: 0;
      transition: all 0.2s;
    }
  }

  & p {
    color: #202020;
    font-size: 14px;
  }
}

@media (max-width: 400.98px) {
  .form {
    &_action {
      & a,
      .checkbox p {
        font-size: 12px;
      }
    }
  }
}
