.SectionTemplate {
    width: 100%;
    background: #202020;
    border-radius: 100px 100px 0 0;
    margin-bottom: 65px;
    
    &__header {
        color: #fff;
        font-size: 54px;
        text-transform: uppercase;
        padding: 60px 0 42px;
    }

    &__inner {
        display: flex;
        justify-content: space-around;
    }
}
