.Info {
    max-width: 400px;
    margin-bottom: 165px;

    &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #EAC131;
        margin-bottom: 45px;
        font-size: 30px;

        &-wrap{
            display: flex;
        }
    }

    &__title {
        text-transform: uppercase;
        font-size: 22px;
        color: #669005;
        margin-bottom: 30px;
    }

    &__desc {
        font-size: 20px;
        color: #F7F8FA
    }

    
}
