.container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 60px;
}

.nav {
    list-style-type: none;
}

input {
    font-family: 'Fontin Sans CR', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

.hr {
    width: 100%;
    height: 1px;
    background-color: rgba(247, 248, 250, .5);
    margin-bottom: 85px;
    }

.fz72 {
    font-size: 72px;
}

.pt0 {
    padding-top: 0;
}

.mb0 {
    margin-bottom: 0;
}
