.button {
  width: 180px;
  height: 35px;

  color: #fff;
  font-size: 16px;
  font-weight: 700;

  background-color: #a9b81b;
  border-radius: 22.78px;
  border: none;

  cursor: pointer;
}
