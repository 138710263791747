.Banner {
    padding: 86px 0 100px;
    margin-bottom: 45px;
    text-transform: uppercase;
    font-size: 45px;
    color: #A9CF54;
    border-bottom: 1px solid rgba(247, 248, 250, .5);
    
    &__content {
        display: flex;
        justify-content: space-between;
    }
    
    &__title {
        max-width: 880px;
        margin-bottom: 80px;
    }
    
    span {
        display: inline-block;
        width: 337px;
        font-size: 21px;
        color: #fff;
        margin-left: 30px;
    }

    &__link {
        display: block;
        max-width: 350px;
        text-decoration: none;
        font-size: 20px;
        color: #fff;
        padding: 19px 0;
        border: 2px solid #A9CF54;
        border-radius: 50px;
        text-align: center;

        &:hover {
            color: #202020;
            background-color: #A9CF54;
            border-color: rgb(37, 40, 30, 0.502);
        }
    }

    &__scroll {
        cursor: pointer;
        align-self: flex-end; 
    }

    &__nav {
        margin-top: 70px;
        &-items {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        &-item {
            a {    
                display: block;
                height: 0;
            }
            width: 20px;
            height: 20px;
        }
    }
}
