.Header {
    border-bottom: 1px solid rgba(247, 248, 250, .5);
    
    &__wrap {
        display: flex;
        margin: 13px 0;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
    }

    &__logo {
        cursor: pointer;
    }

    &__nav {
        
        &-items {
            display: flex;
        }
    
        &-item {
            margin-right: 50px;
            color: #A9CF54;
            font-weight: 700;
            font-size: 22px;
            cursor: pointer;
    
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

