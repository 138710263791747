.signup {
  background-color: #fff;
  border-radius: 40px;

  padding: 61.5px 59.5px 40.5px;

  max-width: 550px;
  margin: 0 auto;

  & h1 {
    text-align: center;

    font-size: 32px;
    text-transform: uppercase;

    margin-bottom: 25px;
  }

  @media (max-width: 539.98px) {
    padding: 40px 15px;

    & h1 {
      font-size: 26px;

      margin-bottom: 20px;
    }
  }

  @media (max-width: 379.98px) {
    & h1 {
      font-size: 22px;
    }
  }
}
