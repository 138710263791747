body {
  margin: 0;
  font-family: 'Fontin Sans CR', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #202020;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input {
  &::placeholder {
    color: #202020;
  }
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.eot');
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.eot');
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.ttf');
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.ttf');
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.woff');
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.woff');
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.woff2');
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.woff2');
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.svg');
}

@font-face {
  font-family: 'Fontin Sans CR';
  src: url('./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.svg');
}
