.Parthners {
    margin-bottom: 35px;

    &__wrap {
        display: flex;
    }
    
    &__item {
        padding: 25px 35px;
        color: #202020;
        background: #E2FAAD;
        border-radius: 500px;
        margin-right: 20px;
        font-size: 22px;
        margin-bottom: 40px;
    }

    &__desc {
        text-align: center;
        display: block;
        color: #FFF;
        opacity: .7;
        font-size: 25px;
    }
}
