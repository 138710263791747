.UserCommentCard {
    
    &__inner {
        margin: 0 auto 50px;
        padding: 40px 64px;
        border: 4px solid rgba(247, 248, 250, 0.502);
        border-radius: 40px;
        max-width: 760px;
    }

    &__msg {
        display: flex;
        font-size: 22px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 40px;

        &::before {
            content: '';
            background: url(../../../assets/img/quotes.svg) no-repeat;
            display: block;
            margin-right: 15px;
            width: 65px;
            height: 20px;
        }
    }

    &__users {
        display: flex;
        align-items: center;
        justify-content: space-around;   

        &-current {
            display: flex;
            align-items: center;
            padding: 16px 26px;
            margin-right: 40px;
            border: 4px solid #A9CF54;
            border-radius: 100px;
        }
    }

    .user {
        &__current-icon {
            margin-right: 25px;
        }

        &__current-title {
            margin: 0;
            color: #fff;
            font-size: 20px;
        }

        &__current-position {
            display: block;
            font-weight: 400;
            color: #6E6E6E;
            font-size: 16px;
        }

        &__all-icon {
            cursor: pointer;
            margin-left: -20px;
        }
    }
}
