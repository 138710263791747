.Input {
    color: #202020;
    padding: 32px 85px;
    font-size: 25px;
    background: transparent;
    outline: none;
    border: 4px solid #A9CF54;
    border-radius: 500px;
}

  